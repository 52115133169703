import React from 'react';
import anime from 'animejs';
import locationPin from './assets/map-pin.svg';
import './App.css';
import { ArrowUpRight } from 'react-feather';

export default function App() {
  const dripAnimationRef = React.useRef(null);
  const dripAnimationRef2 = React.useRef(null);
  const dripAnimationRef3 = React.useRef(null);
  const dripAnimationRef4 = React.useRef(null);
  const dripAnimationRef5 = React.useRef(null);
  const dripAnimationRef6 = React.useRef(null);
  const dripAnimationRef7 = React.useRef(null);
  React.useEffect(() => {
    dripAnimationRef.current = anime({
      targets: '.drip',
      translateY: 1000,
      delay: anime.stagger(100, { start: 50 }),
      loop: true,
      easing: 'easeInCubic',
    });
  }, []);

  React.useEffect(() => {
    dripAnimationRef2.current = anime({
      targets: '.drip2',
      translateY: 1000,
      delay: anime.stagger(100, { start: 175 }),
      loop: true,
      easing: 'easeInCubic',
    });
  }, []);

  React.useEffect(() => {
    dripAnimationRef3.current = anime({
      targets: '.drip3',
      translateY: 1000,
      delay: anime.stagger(100, { start: 500 }),
      loop: true,
      easing: 'easeInCubic',
    });
  }, []);

  React.useEffect(() => {
    dripAnimationRef4.current = anime({
      targets: '.drip4',
      translateY: 1000,
      delay: anime.stagger(100, { start: 750 }),
      loop: true,
      easing: 'easeInCubic',
    });
  }, []);

  React.useEffect(() => {
    dripAnimationRef5.current = anime({
      targets: '.drip5',
      translateY: 1000,
      delay: anime.stagger(100, { start: 1150 }),
      loop: true,
      easing: 'easeInCubic',
    });
  }, []);

  React.useEffect(() => {
    dripAnimationRef6.current = anime({
      targets: '.drip6',
      translateY: 1000,
      delay: anime.stagger(100, { start: 200 }),
      loop: true,
      easing: 'easeInCubic',
    });
  }, []);

  React.useEffect(() => {
    dripAnimationRef7.current = anime({
      targets: '.drip7',
      translateY: 1000,
      delay: anime.stagger(100, { start: 300 }),
      loop: true,
      easing: 'easeInCubic',
    });
  }, []);

  return (
    <div>
      <div className="parent">
        <div className="one"></div>
      </div>

      <div className="parent">
        <div className="two-left">
          <div className="drip"></div>
          <div className="drip2"></div>
          <div className="drip3"></div>
          <div className="drip4"></div>
          <div className="drip5"></div>
          <div className="drip6"></div>
          <div className="drip7"></div>
        </div>
        <div className="two-main">
          <div className="name">Tristan Dardani</div>
          <div className="location">
            <img className="mapPin" src={locationPin} alt="NYC" />
            <div>NYC</div>
          </div>
          <br />
          <div className="header">Experience:</div>
          <i className="titleText">Charles Schwab</i>
          <div>Software Engineer</div>
          <div>Jan 2023 - Present</div>
          <br />
          <i className="titleText">Charles Schwab</i>
          <div>Software Engineer Intern</div>
          <div>Jun 2022 - Aug 2022</div>
          {/* <br />
          */}
          {/* <div>
            <i className="titleText" >Visualized Haskell Functions</i>
          </div>
          <div>Animated visualization of various Haskell methods from map to foldr</div> */}
          {/* <i>Technologies: React, Typescript, Framer Motion</i>
           */}

          <br />
          <div className="header">Projects/Extras:</div> 
          <div>
            <a className="link" target="_blank" rel="noreferrer noopener" href="https://leetcode.td31.dev/">
              <i className="titleText">LeetCode Notes</i>
            </a>
            <ArrowUpRight size={16} />
          </div>
          <div>Handful of completed LeetCode solutions in Python with notes</div>

          {/* <i>Technologies: React, Typescript</i>
          <br />
          <br />
          <div>
            <i className="titleText">Advent of Code 2022 - Haskell</i>
          </div>
          <div>Completed Advent of Code 2022 in Haskell as a refresher/learning excerise</div>
          <i>Technologies: Haskell</i> */}
          <br />
          <div className="header">Links:</div>
          <div>
            <i>
              <a className="link" target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/tristan-dardani-00568099/">
                Linkedin
              </a>
            </i>
            <ArrowUpRight size={16} />
          </div>
          <div>
            <i>
              <a className="link" target="_blank" rel="noreferrer noopener" href="https://github.com/td31">
                Github
              </a>
            </i>
            <ArrowUpRight size={16} />
          </div>
        </div>
      </div>
    </div>
  );
}
